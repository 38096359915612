import React, { useState, useEffect } from 'react';
import axios from 'axios';

import DataTableTop30 from '../../components/DataTableTop30';
import StrategyTable from '../../components/StrategyTable';
import TopSectorTable from '../../components/TopSectorTable';
import extractDateComponents from '../../tool/extractDateTime';

const HomePage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [date, setDate] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://trueinvestvn.site/api/daily/lastest');
        setData(response.data);
        
        // Extract date components and format the date string
        const { year, month, day } = extractDateComponents(response.data.entryDate);
        const formattedDate = `${day}/${month}/${year}`;
        setDate(formattedDate);

        console.log(response.data)
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="home_page">
      <div className="signal_table">
        <div>
          <h2>30 Mã CP đáng chú ý</h2>
          {data && data.topRsStock ? (
            <DataTableTop30 data={data.topRsStock} />
          ) : (
            <p>No data available</p>
          )}

          <h2>Các ngành/ Index đáng chú ý</h2>
          {data && data.topRsSector ? (
            <TopSectorTable data={data.topRsSector} />
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>

      <div className="strategy_table">
        <div>
          <h2>Chiến lược thị trường ngày {date}</h2>
          {data ? (
            <StrategyTable data={[
              data.marketToday,
              data.marketTrend,
              data.timeTrend,
              data.strategy,
              data.capitalAllocation
            ]} />
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
