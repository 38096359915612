// JavaScript Function to Extract Year, Month, and Date
// A function that takes a date string in ISO 8601 format and returns the year, month, and date.

const extractDateComponents = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Extract year, month, and date
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // Months are zero-based, so add 1
    const day = date.getUTCDate();

    // Return the components
    return {
        year: year,
        month: month,
        day: day
    };
}

// Example usage
// const dateString = '2024-08-01T00:00:00.000Z';
// const components = extractDateComponents(dateString);

// console.log(components); 
// Outputs: { year: 2024, month: 8, day: 1 }

export default extractDateComponents
