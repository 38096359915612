import React from "react";

// This component will render 6 index. sector that has most RS  in to a table
const TopSectorTable = ({ data }) => {
    // Determine the data to display (all if <= 6, first 6 if > 6)
    const displayData = data.length > 6 ? data.slice(0, 6) : data;
  
    return (
      <div className="table-container">
            <table className="half-width-table">
              <thead>
                <tr>
                  <th>Index/ Ngành</th>
                </tr>
              </thead>
              <tbody>
                {displayData.map((item, index) => (
                  <tr key={index}>
                    <td>{item}</td>
                  </tr>
                ))}
              </tbody>
            </table>
      </div>
    );
  };

  export default TopSectorTable;