import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Routes } from 'react-router-dom';
import './App.css';


import HomePage from './page/Homepage/Homepage';

const RootLayout = () => (
  <>
    {/* <Navbar /> */}
    <Routes>
      <Route path="/" element={<HomePage />} />
    </Routes>
  </>
);



const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/*" element={<RootLayout />} />
));


function App() {
  return (
    <div className="App">
      <RouterProvider router={ router } />
    </div>
  );
}

export default App;
